import * as React from "react";
import LayoutComponent from "../components/layout";

import ContactForm from "../components/forms/contactform";

// markup
const Pieces = () => {
  return (
    <LayoutComponent>
      <ContactForm />
    </LayoutComponent>
  );
};

export default Pieces;
